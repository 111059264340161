<template>
  <section id="grading-assessment">
    <Breadcrumb
      :links="[
        {
          label: grading.type_of_assessment.replace('_', ' '),
          link: true,
          route: { name: 'Instructor Grading Course', params: { id: grading.course_id }, 
            query: { panel : 
                grading.type_of_assessment === 'graded_assessment' ? 0 
                  :  1 , 
              page: 1 } },
        },
        {
          label: grading.title,
          link: false,
          route: null,
        },
      ]"
    />
    <v-text-field
      solo
      dense
      flat
      class="col-xl-3 col-lg-4 custom-border mb-5 general-custom-field f14 poppins fw500"
      hide-details
      prepend-inner-icon="mdi-magnify"
      placeholder="Search student name"
      v-model="search"
    />
    <v-data-table
      :headers="grading_assessment_student_tbl"
      :items="gradingAssessment"
      :search="search"
      class="text--center poppins custom-border"
      :page.sync="page"
      hide-default-footer
      :items-per-page="itemsPerPage"
      @page-count="pageCount = $event"
      :loading="loading">
      <template v-slot:item.checked="{ item }">
        <span :class="item.checked == 'Completed' ? 'green--text' : 'grey--text'">{{ item.checked }}</span>
      </template>
      <template v-slot:item.date="{ item }">
        {{$dateFormat.mmDDyy(item.created_at)}}
      </template>
      <template v-slot:item.time="{ item }">
        {{$dateFormat.hhMM(item.created_at)}}
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn
          text
          exact
          class="primary--text text-capitalize roboto f14"
          :to="{
            name: 'Instructor Grading Assessment User',
            params: { id: item.assessment_id, user_id: item.user.id },
          }"
          icon
        >
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-row align="center" class="ma-2" v-if="gradingAssessment.length > 0">
      <v-col lg="1" class="hidden-md-and-down">
        <v-text-field
          :value="itemsPerPage"
          label="Items"
          type="number"
          min="-1"
          max="15"
          @input="itemsPerPage = parseInt($event, 10)"
        ></v-text-field>
      </v-col>

      <v-col lg="10" md="12">
        <v-pagination
          v-model="page"
          :length="pageCount"
          :total-visible="7"
        ></v-pagination>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Breadcrumb from "../../layouts/teacher/InstructorBreadcrumbs.vue";
import { grading_assessment_student_tbl } from '../../constants/tblheaders/grading';

export default {
  data: () => ({
    grading_assessment_student_tbl,
    search: '',
    pageCount: 0,
    itemsPerPage: 5,
    loading: true,
  }),
  methods: {
    ...mapActions('instructor', ['getGradingStudentList'])
  },
  mounted(){
    if(!this.$route.query || !this.$route.query.page) {
      this.$router.replace({ query: { page: 1 , timestamp: Date.now() } });
    } else {
      this.$router.replace({ query: { page: this.$route.query.page , timestamp: Date.now() } });
    }
    this.getGradingStudentList(this.$route.params.id).then(()=>{
      this.loading = false
    })
  },
  components: { Breadcrumb },
  computed:{
    ...mapState('instructor', {
      grading: (state) => state.grading,
      gradingAssessment(state){
        let items = []
        state.gradingUsers.forEach(item => {
          items.push(
            { 
              created_at: item.created_at,
              student: `${item.user.first_name} ${item.user.last_name}`, 
              score: state.grading.assessment_questions_sum_score ? `${item.score} / ${state.grading.assessment_questions_sum_score} pts` : '-',
              checked: item.checked ? "Completed" : "Pending",
              assessment_id: state.grading.id,
              user: item.user,
              id: item.id })
        })
        return items
      }
    }),
    page: {
      get() {
        let page = parseInt(this.$route.query.page);
        return page;
      },
      set(page) {
        this.$router.replace({ query: { page: page, timestamp: Date.now() } });
      },
    },
  },
};
</script>

<style></style>

